import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { selectBaseURL } from "../store/url/urlSlice";
import { useParams } from "react-router-dom";

const UserLogBox = ({ userID, nrkOrNip }) => {
  
  const [logWorkData, setLogWorkData] = useState({
    DAY: "-",
    WEEK: "-",
    MONTH: "-",
  });
  const [usersData, setUsersData] = useState({
    totalTime: "-",
    lastLogin: "-",
    lastLogout: "-",
    noLogoutCount: "-",
  });
  const [loading, setLoading] = useState(true);
  const baseURL = useSelector(selectBaseURL);
  const usednrkOrNip = nrkOrNip || localStorage.getItem("NRKorNIP");
  const token = localStorage.getItem("Token");
  const authToken = `Bearer ${token}`;

  useEffect(() => {
    const fetchLogWork = async () => {
      if (!nrkOrNip) {
        console.error("NRKorNIP tidak ditemukan di localStorage.");
        return;
      }

      try {
        const logApiUrl = `${baseURL}/geoapi/gpsweb/LOGtime?NRKorNIP=${usednrkOrNip}`;
        const response = await axios.get(logApiUrl, {
          headers: {
            Authorization: authToken,
          },
        });
        const { DAY, WEEK, MONTH } = response.data.data || {};

        setLogWorkData({
          DAY: DAY || "-",
          WEEK: WEEK || "-",
          MONTH: MONTH || "-",
        });
      } catch (error) {
        console.error("Error fetching log work data:", error);
      }
    };

    fetchLogWork();
  }, [baseURL, usednrkOrNip, authToken]);

  useEffect(() => {
    const formatDateTime = (dateTime) => {
      if (!dateTime || dateTime === "-") return "-";
      const date = new Date(dateTime);
      const formattedDate = date.toLocaleDateString("en-GB").replace(/\//g, "-");
      const formattedTime = date.toLocaleTimeString([], {
        hour12: false,
        hour: "2-digit",
        minute: "2-digit",
      });
      return `${formattedDate} ${formattedTime}`;
    };

    const fetchUserLogData = async () => {
      if (!nrkOrNip) {
        console.error("NRKorNIP tidak ditemukan di localStorage.");
        setLoading(false);
        return;
      }

      try {
        setLoading(true);
        const response = await axios.get(
          `${baseURL}/geoapi/gpsweb/viewUserLog?nrk_or_nip=${usednrkOrNip}`,
          {
            headers: {
              Authorization: authToken,
            },
          }
        );
        const data = response.data || {};

        setUsersData({
          totalTime: data.totalTime || "-",
          lastLogin: formatDateTime(data.lastLogin),
          lastLogout: formatDateTime(data.lastLogout),
          noLogoutCount: data.noLogoutCount || "-",
        });
      } catch (error) {
        console.error("Error fetching user log data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchUserLogData();
  }, [baseURL, usednrkOrNip, authToken]);

  if (loading) {
    return (
      <div className="p-4 text-center">
        <p>Loading data...</p>
      </div>
    );
  }

  return (
    <div className="p-4 bg-gray-50">
      <div className="flex flex-wrap justify-between gap-4 mb-4">
        <div className="bg-blue-200 p-4 rounded-lg shadow-md flex-1 min-w-[250px]">
          <p className="text-sm text-black font-medium">Today</p>
          <h3 className="text-blue-600 text-4xl font-bold">{logWorkData.DAY}</h3>
        </div>

        <div className="bg-green-200 p-4 rounded-lg shadow-md flex-1 min-w-[250px]">
          <p className="text-sm text-black font-medium">This Week</p>
          <h3 className="text-green-600 text-4xl font-bold">{logWorkData.WEEK}</h3>
        </div>

        <div className="bg-teal-100 p-4 rounded-lg shadow-md flex-1 min-w-[250px]">
          <p className="text-sm text-black font-medium">This Month</p>
          <h3 className="text-teal-600 text-4xl font-bold">{logWorkData.MONTH}</h3>
        </div>
      </div>

      <div className="flex flex-wrap justify-between gap-4 mb-4">
        <div className="bg-green-200 p-4 rounded-lg shadow-md flex-1 min-w-[250px]">
          <p className="text-sm text-black font-medium">Last Login</p>
          <h3 className="text-green-600 text-4xl font-bold">{usersData.lastLogin}</h3>
        </div>

        <div className="bg-yellow-200 p-4 rounded-lg shadow-md flex-1 min-w-[250px]">
          <p className="text-sm text-black font-medium">Last Logout</p>
          <h3 className="text-yellow-600 text-4xl font-bold">{usersData.lastLogout}</h3>
        </div>

        <div className="bg-red-200 p-4 rounded-lg shadow-md flex-1 min-w-[250px]">
          <p className="text-sm text-black font-medium">No Logout Count</p>
          <h3 className="text-red-600 text-4xl font-bold">{usersData.noLogoutCount}</h3>
        </div>
      </div>
    </div>
  );
};

export default UserLogBox;
