import React, { useEffect, useState } from "react";
import axios from "axios";
import Layout from "../components/Layout";
import TableUserlist from "../components/TableUserlist";
import iconPetugas from "../assets/images/icon-petugas.png";
import iconKapal from "../assets/images/icon-kapal.png";
import iconKDO from "../assets/images/icon-kdo.png";
import iconBusUPAS from "../assets/images/icon-BusUPAS.png";
import { useSelector } from "react-redux";
import { selectBaseURL } from "../store/url/urlSlice";

const userID = localStorage.getItem("IDs");
const Role=localStorage.getItem("Role");
// console.log(userID);
const ViewAllUsers = () => {
  
  return (
    <Layout>
      <div className="p-4">
        {/* <h2 className="text-2xl font-bold text-center mb-4">Daftar User</h2> */}
        <TableUserlist userID={userID} />
      </div>

      {/* <div>
        <h2 className="text-md font-bold dark:text-white text-left mb-2 mt-5">
          Keterangan
        </h2>
        <br></br>
        <table className="table-auto w-60 border-collapse border-2">
          <thead className="sticky top-0 dark:text-white dark:bg-gray-800">
            <tr>
              <th className="border p-2 dark:text-white font-semibold">
                Jenis
              </th>
              <th className="border p-2 dark:text-white font-semibold">Icon</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="border p-2 dark:text-white">Kapal</td>
              <td className="border p-2 dark:text-white">
                <img src={iconKapal} alt="iconKapal" className="w-6 h-6" />
              </td>
            </tr>
            <tr>
              <td className="border p-2 dark:text-white">KDO</td>
              <td className="border p-2 dark:text-white">
                <img src={iconKDO} alt="iconKDO" className="w-6 h-6" />
              </td>
            </tr>
            <tr>
              <td className="border p-2 dark:text-white">Petugas</td>
              <td className="border p-2 dark:text-white">
                <img src={iconPetugas} alt="iconPetugas" className="w-6 h-6" />
              </td>
            </tr>
            <tr>
              <td className="border p-2 dark:text-white">Bus Sekolah</td>
              <td className="border p-2 dark:text-white">
                <img src={iconBusUPAS} alt="iconBusUPAS" className="w-6 h-6" />
              </td>
            </tr>
          </tbody>
        </table>
      </div> */}
    </Layout>
  );
};

export default ViewAllUsers;
