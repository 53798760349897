import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import Layout from '../components/Layout';
import { useSelector } from 'react-redux';
import { selectBaseURL } from '../store/url/urlSlice';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';

const EditProfile = () => {
  const [user, setUser] = useState({
    userID: '',
    StatusAkun: null,
    nama: '',
  });
  const token = localStorage.getItem('Token');
  const authToken = `Bearer ${token}`;
  const { userID } = useParams();
  const nrk = localStorage.getItem('NRKorNIP');
  const baseURL = useSelector(selectBaseURL);
  const navigate = useNavigate();
  const usedUserID = userID || nrk;

  useEffect(() => {
    if (!usedUserID) return;

    const fetchUserData = async () => {
      try {
        const response = await axios.get(`${baseURL}/geoapi/user/view/${usedUserID}`, {
          headers: { Authorization: authToken },
        });
        const userData = response.data;
        setUser({
          userID: userData?.userID || '',
          StatusAkun: userData?.StatusAkun || null,
          nama: userData?.Nama || '',
        });
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchUserData();
  }, [usedUserID, baseURL, authToken]);

  const handleUpdateProfile = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.put(
        `${baseURL}/geoapi/user/userActivation`,
        {
          userID: user.userID,
          StatusAkun: user.StatusAkun,
        },
        {
          headers: { Authorization: authToken },
        }
      );

      if (response.status === 200) {
        Swal.fire({
          title: 'Berhasil',
          text: 'Status akun berhasil diperbarui.',
          icon: 'success',
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'OK',
        }).then(() => {
          navigate(`/profile/${user.userID}`);
        });
      } else {
        throw new Error(`Unexpected response status: ${response.status}`);
      }
    } catch (error) {
      let errorMessage = 'Terjadi kesalahan saat memperbarui status akun.';
      if (error.response) {
        switch (error.response.status) {
          case 400:
            errorMessage = 'Permintaan tidak valid.';
            break;
          case 401:
            errorMessage = 'Anda tidak memiliki izin untuk melakukan ini.';
            break;
          case 404:
            errorMessage = 'User tidak ditemukan.';
            break;
          default:
            errorMessage = 'Terjadi kesalahan pada server.';
        }
      }
      Swal.fire({
        title: 'Gagal',
        text: errorMessage,
        icon: 'error',
        confirmButtonColor: '#d33',
        confirmButtonText: 'OK',
      });
      console.error('Error updating user status:', error);
    }
  };

  const handleInputChange = (key, value) => {
    setUser((prevUser) => ({
      ...prevUser,
      [key]: key === 'StatusAkun' ? value === 'true' : value,
    }));
  };

  return (
    <Layout>
      <div className="flex justify-center items-center min-h-screen bg-gray-100">
        <div className="w-full max-w-md p-8 bg-white rounded shadow-md">
          <h2 className="text-2xl font-semibold mb-4">Edit Profile</h2>
          <div className="overflow-y-auto max-h-96">
            <form>
              <div className="mb-4">
                <label className="font-semibold mb-2">Nama</label>
                <input
                  type="text"
                  value={user.nama}
                  className="w-full bg-gray-200 p-2 rounded"
                  readOnly
                />
              </div>
              <div className="mb-4">
                <label className="font-semibold mb-2">User ID:</label>
                <input
                  type="text"
                  value={user.userID}
                  className="w-full bg-gray-200 p-2 rounded"
                  readOnly
                />
              </div>
              <div className="mb-4">
                <label className="font-semibold mb-2">Status Akun:</label>
                <select
                  value={user.StatusAkun !== null ? user.StatusAkun.toString() : ''}
                  onChange={(e) => handleInputChange('StatusAkun', e.target.value)}
                  className="w-full bg-gray-200 p-2 rounded"
                >
                  <option value="">Pilih Status</option>
                  <option value="true">Active</option>
                  <option value="false">Disabled</option>
                </select>
              </div>
              <button
                type="button"
                onClick={handleUpdateProfile}
                className="w-full bg-blue-500 text-white py-2 px-4 rounded"
              >
                Save
              </button>
            </form>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default EditProfile;
