import React from 'react';
import { ServerIcon } from '@heroicons/react/24/solid'; // Heroicons v2

const Bottom = () => {
  return (
    <div className="fixed bottom-0 left-0 w-full dark:bg-gray-800 p-2 flex justify-between items-center text-sm">
      {/* Server Status Icon */}
      <div>
        <a
          href="https://stats.uptimerobot.com/AN0mbIr8yV"
          className="flex items-center hover:underline"
          target="_blank"
          rel="noopener noreferrer"
        >
          <ServerIcon className="h-5 w-5 mr-1 text-white animate-bounce" />
          <span className="text-white animate-bounce">Server Status</span>
        </a>
      </div>
      {/* Copyright Text */}
      <div className="dark:text-white text-right">
        <p>Geo V.1.1.0</p>
        <p>&copy; 2023. Pusat Data dan Informasi Dinas Perhubungan Provinsi DKI Jakarta. All rights reserved.</p>
      </div>
    </div>
  );
};

export default Bottom;
