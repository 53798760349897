import React, { useState, useEffect } from "react";
import axios from "axios";
import logoImage from "../assets/images/loc.gif";
import TableUserlog from "../components/TableUserlog";
import UserLogBox from "../components/UserLogBox";
import Layout from "../components/Layout";
import { useParams } from "react-router-dom";
import { selectBaseURL } from "../store/url/urlSlice";
import { useSelector } from "react-redux";

const UserProfile = () => {
  const [profile, setProfile] = useState({
    NRKorNIP: "",
    Nama: "",
    Email: "",
    StatusAkun: "",
    Satuan: "",
    Role: "",
    userID: "",
    UniqueCode: "",
  });
  
  const [loading, setLoading] = useState(true);

  const token = localStorage.getItem("Token");
  const authToken = `Bearer ${token}`;
  const baseURL = useSelector(selectBaseURL);
  const userIDLog = localStorage.getItem("IDs");
  const { userID,NRKorNIP } = useParams();
  const nrkOrNipLog = localStorage.getItem("NRKorNIP");
  const usedUserID = userID || userIDLog;
  const usedNrkOrNip = NRKorNIP || nrkOrNipLog;
  // console.log(NRKorNIP);
  // console.log(usedUserID,usedNrkOrNip);
  // console.log('useParams:', { userID, NRKorNIP });
  // console.log('localStorage NRKorNIP:', nrkOrNipLog);
  // console.log('usedNrkOrNip:', usedNrkOrNip);
  
  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await axios.get(`${baseURL}/geoapi/user/view/${usedUserID}`, {
          headers: { Authorization: authToken },
        });
        setProfile(response.data);
      } catch (error) {
        console.error("Error fetching profile:", error);
      } finally {
        setLoading(false); // Set loading to false after data is fetched
      }
    };

    if (usedUserID) fetchProfile();
  }, [usedUserID, baseURL, authToken]);

  if (loading) {
    return (
      <Layout>
        <div className="flex justify-center items-center min-h-screen">
          <p>Loading...</p>
        </div>
      </Layout>
    );
  }

  return (
    <Layout>
      <div className="flex justify-center items-center min-h-screen bg-gray-100">
        <div className="bg-gray p-6 rounded-lg shadow-md w-full">
          {/* Header Section */}
          <div className="grid grid-cols-3 gap-4 mb-4 bg-white p-4 rounded-lg">
            <div className="flex items-center">
              <img
                src={logoImage}
                alt="Logo"
                className="rounded-full mr-4 w-24 h-auto"
              />
              <div className="text-start">
                <div className="mb-2">
                  <p className="font-semibold">Nama</p>
                  {profile.Nama}
                </div>
                <div className="mb-2">
                  <p className="font-semibold">UserId</p>
                  {profile.userID}
                </div>
              </div>
            </div>
          </div>

          {/* Profile Details */}
          <div className="grid grid-cols-3 gap-4 mb-4 bg-blue-900 text-white p-4 rounded-lg">
            <div>
              <p className="font-semibold">Role:</p>
              {profile.Role}
            </div>
            <div>
              <p className="font-semibold">NRK or NIP:</p>
              {profile.NRKorNIP}
            </div>
            <div>
              <p className="font-semibold">Satuan:</p>
              {profile.Satuan}
            </div>
            <div>
              <p className="font-semibold">Unique Code:</p>
              {profile.UniqueCode}
            </div>
            <div>
              <p className="font-semibold">Status Akun:</p>
              {profile.StatusAkun ? "Active" : "Disabled"}
            </div>
            <div>
              <p className="font-semibold">Email:</p>
              {profile.Email}
            </div>
          </div>

          {/* User Log and Table */}
           <UserLogBox userID={usedUserID} nrkOrNip={usedNrkOrNip} />
          <TableUserlog userID={usedUserID} nrkOrNip={usedNrkOrNip} />
        </div>
      </div>
    </Layout>
  );
};

export default UserProfile;
