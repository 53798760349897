import React, { useState } from 'react';
import axios from 'axios';
import Layout from '../components/Layout';
import { Link } from 'react-router-dom';
import { selectBaseURL } from '../store/url/urlSlice';
import { useSelector } from 'react-redux';

const CreateArea = () => {
  //State untuk meyimpan data area yang akan dibuat 
  const [areaName, setAreaName] = useState('');
  const [areaDescription, setAreaDescription] = useState('');
  const [longitude, setLongitude] = useState('');
  const [latitude, setLatitude] = useState('');
  const [radius, setRadius] = useState('');
  const [sudin, setSudin] = useState('');
  const [kelurahan, setKelurahan] = useState('');
  const [kecamatan, setKecamatan] = useState('');
  const [alamat, setAlamat] = useState('');
  const baseURL = useSelector(selectBaseURL);
  const handleSubmit = async (e) => {
    e.preventDefault();

    //Membuat objek baru yang berisi data area
    const newArea = {
      name: areaName,
      description: areaDescription,
      longitude: parseFloat(longitude),
      latitude: parseFloat(latitude),
      radius: parseFloat(radius),
      sudin,
      kelurahan,
      kecamatan,
      alamat,
    };

    try {
      const response = await axios.post(
        `${baseURL}/geoapi/area/carea`,
        newArea
      );

      // console.log('Area created:', response.data);

      // Reset nilai input formulir
      setAreaName('');
      setAreaDescription('');
      setLongitude('');
      setLatitude('');
      setRadius('');
      setSudin('');
      setKelurahan('');
      setKecamatan('');
      setAlamat('');
    } catch (error) {
      console.error('Error creating area:', error);
    }
  };

  return (
    <Layout>
      <div className="flex-grow flex items-center justify-center">
        <div className="max-w-md border p-4">
          <h2 className="text-xl dark:text-white font-semibold mb-4">Create Area</h2>
          <form onSubmit={handleSubmit} className="grid gap-4 grid-cols-2">
            {/* First Column */}
            <div>
              <label className="block dark:text-white text-sm font-medium mb-1">Area Name</label>
              <input
                type="text"
                value={areaName}
                onChange={(e) => setAreaName(e.target.value)}
                className="w-full p-2 border rounded-md focus:outline-none focus:ring focus:ring-blue-300"
                required
              />
            </div>
            <div>
              <label className="block dark:text-white text-sm font-medium mb-1">Longitude</label>
              <input
                type="text"
                value={longitude}
                onChange={(e) => setLongitude(e.target.value)}
                className="w-full p-2 border rounded-md focus:outline-none focus:ring focus:ring-blue-300"
                required
              />
            </div>
            <div>
              <label className="block dark:text-white text-sm font-medium mb-1">Latitude</label>
              <input
                type="text"
                value={latitude}
                onChange={(e) => setLatitude(e.target.value)}
                className="w-full p-2 border rounded-md focus:outline-none focus:ring focus:ring-blue-300"
                required
              />
            </div>
            <div>
              <label className="block dark:text-white text-sm font-medium mb-1">Radius</label>
              <input
                type="text"
                value={radius}
                onChange={(e) => setRadius(e.target.value)}
                className="w-full p-2 border rounded-md focus:outline-none focus:ring focus:ring-blue-300"
                required
              />
            </div>

            {/* Second Column */}
            <div>
              <label className="block dark:text-white text-sm font-medium mb-1">Sudin</label>
              <input
                type="text"
                value={sudin}
                onChange={(e) => setSudin(e.target.value)}
                className="w-full p-2 border rounded-md focus:outline-none focus:ring focus:ring-blue-300"
                required
              />
            </div>
            <div>
              <label className="block dark:text-white text-sm font-medium mb-1">Kelurahan</label>
              <input
                type="text"
                value={kelurahan}
                onChange={(e) => setKelurahan(e.target.value)}
                className="w-full p-2 border rounded-md focus:outline-none focus:ring focus:ring-blue-300"
                required
              />
            </div>
            <div>
              <label className="block dark:text-white text-sm font-medium mb-1">Kecamatan</label>
              <textarea
                type="text"
                value={kecamatan}
                onChange={(e) => setKecamatan(e.target.value)}
                className="w-full p-2 border rounded-md focus:outline-none focus:ring focus:ring-blue-300"
                rows="4"
                required
              />
            </div>
            <div>
              <label className="block dark:text-white text-sm font-medium mb-1">Alamat</label>
              <textarea
                value={alamat}
                onChange={(e) => setAlamat(e.target.value)}
                className="w-full p-2 border rounded-md focus:outline-none focus:ring focus:ring-blue-300"
                rows="4"
                required
              />
            </div>

            {/* Submit Button */}
            <div className="col-span-2 flex justify-center">
            <Link to="/areas" className="mr-4">
                <button className="bg-gray-300 text-gray-700 py-2 px-4 rounded-md hover:bg-gray-400">
                   Back {/* Unicode arrow character */}
                </button>
              </Link>
              <button
                type="submit"
                className="bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600"
              >
                Create Area
              </button>
            </div>
          </form>
        </div>
      </div>
    </Layout>
  );
};

export default CreateArea;
