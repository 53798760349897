import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { CgEye, CgPen, CgTrash } from "react-icons/cg";
import { FaCheck, FaTimes } from "react-icons/fa";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import { selectBaseURL } from "../store/url/urlSlice";
import PropTypes from "prop-types";

const TableUserlist = ({ userID, onViewUser }) => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState({
    nama: "",
    jenis: "",
    satuan: "",
    role: "",
  });
  const [filterOptions, setFilterOptions] = useState({
    jenis: [],
    satuan: [],
    role: [],
  });
  const token = localStorage.getItem("Token");
  const authToken = `Bearer ${token}`;
  const navigate = useNavigate();
  const baseURL = useSelector(selectBaseURL);

  const handleViewUser = (userID, NRKorNIP) => {
  //   console.log('userID:', userID);  
  // console.log('NRKorNIP:', NRKorNIP);
    // Jalankan callback onViewUser jika ada
    if (onViewUser) {
      onViewUser(userID, NRKorNIP);
    }
    // Navigasi ke halaman profil
    navigate(`/profile/${userID}/${NRKorNIP}`);
  };

  const handleUpdateStatusUser = (userID) => {
    navigate(`/EditProfile/${userID}`);
  };

  TableUserlist.propTypes = {
    userID: PropTypes.string.isRequired,
  };

  const handleDeleteUser = async (userID) => {
    Swal.fire({
      title: "Konfirmasi Menghapus Profile",
      text: "Anda yakin akan menghapus profile?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Hapus",
      cancelButtonText: "Cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await axios.delete(`${baseURL}/geoapi/user/delete/${userID}`, {
            headers: { Authorization: authToken },
          });

          // Hapus pengguna dari state users
          setUsers((prevUsers) =>
            prevUsers.filter((user) => user.userID !== userID)
          );

          Swal.fire({
            title: "Profile Berhasil Dihapus",
            text: "Anda telah menghapus profile",
            icon: "success",
            confirmButtonColor: "#3085d6",
            confirmButtonText: "OK",
          });
        } catch (error) {
          console.error("Error deleting profile:", error);
          Swal.fire({
            title: "Error",
            text: "Gagal menghapus profile. Silakan coba lagi.",
            icon: "error",
            confirmButtonColor: "#d33",
            confirmButtonText: "OK",
          });
        }
      }
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${baseURL}/geoapi/user/viewu/${userID}`,
          {
            headers: { Authorization: authToken },
          }
        );
        const usersData = response.data;

        const uniqueJenis = [...new Set(usersData.map((user) => user.Jenis))];
        const uniqueSatuan = [...new Set(usersData.map((user) => user.Satuan))];
        const uniqueRole = [...new Set(usersData.map((user) => user.Role))];

        setUsers(usersData);
        setFilterOptions({
          jenis: uniqueJenis,
          satuan: uniqueSatuan,
          role: uniqueRole,
        });
        setLoading(false);
      } catch (error) {
        // console.error('Error fetching data:', error);
        setLoading(false);
      }
    };

    fetchData();
  }, [userID, baseURL]);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters({
      ...filters,
      [name]: value,
    });
  };

  const filteredUsers = users.filter((user) => {
    return (
      (filters.nama === "" ||
        user.Nama.toLowerCase().includes(filters.nama.toLowerCase())) &&
      (filters.jenis === "" || user.Jenis === filters.jenis) &&
      (filters.satuan === "" || user.Satuan === filters.satuan) &&
      (filters.role === "" || user.Role === filters.role)
    );
  });

  const sortedUsers = [...filteredUsers].sort((a, b) => {
    if (a.isActive === b.isActive) return 0;
    return a.isActive ? -1 : 1;
  });

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="p-4 bg-gray-50">
      <h2 className="text-2xl font-bold text-center mb-4">Daftar User</h2>
      {/* Filter Section */}
      <div className="mb-4 grid grid-cols-1 sm:grid-cols-4 gap-4">
        <input
          type="text"
          name="nama"
          value={filters.nama}
          onChange={handleFilterChange}
          placeholder="Cari Nama"
          className="p-2 border border-gray-300 rounded-lg focus:ring focus:ring-blue-300"
        />
        <select
          name="jenis"
          value={filters.jenis}
          onChange={handleFilterChange}
          className="p-2 border border-gray-300 rounded-lg focus:ring focus:ring-blue-300"
        >
          <option value="">Semua Jenis</option>
          {filterOptions.jenis.map((jenis) => (
            <option key={jenis} value={jenis}>
              {jenis}
            </option>
          ))}
        </select>
        <select
          name="satuan"
          value={filters.satuan}
          onChange={handleFilterChange}
          className="p-2 border border-gray-300 rounded-lg focus:ring focus:ring-blue-300"
        >
          <option value="">Semua Satuan</option>
          {filterOptions.satuan.map((satuan) => (
            <option key={satuan} value={satuan}>
              {satuan}
            </option>
          ))}
        </select>
        <select
          name="role"
          value={filters.role}
          onChange={handleFilterChange}
          className="p-2 border border-gray-300 rounded-lg focus:ring focus:ring-blue-300"
        >
          <option value="">Semua Role</option>
          {filterOptions.role.map((role) => (
            <option key={role} value={role}>
              {role}
            </option>
          ))}
        </select>
      </div>

      {/* Table Section */}
      <div className="overflow-x-auto shadow-md rounded-lg">
        <div className="max-h-[500px] overflow-y-auto">
          {" "}
          {/* Max height for scrollable body */}
          <table className="min-w-full divide-y divide-gray-200 bg-white">
            <thead className="sticky top-0 bg-gray-100 z-10">
              {" "}
              {/* Fixed header */}
              <tr>
                <th className="px-4 py-2 text-left text-sm font-semibold text-gray-700 uppercase">
                  No
                </th>
                <th className="px-4 py-2 text-left text-sm font-semibold text-gray-700 uppercase">
                  UserID
                </th>
                <th className="px-4 py-2 text-left text-sm font-semibold text-gray-700 uppercase">
                  NRKorNIP
                </th>
                <th className="px-4 py-2 text-left text-sm font-semibold text-gray-700 uppercase">
                  Nama
                </th>
                <th className="px-4 py-2 text-left text-sm font-semibold text-gray-700 uppercase">
                  Jenis
                </th>
                <th className="px-4 py-2 text-left text-sm font-semibold text-gray-700 uppercase">
                  Satuan
                </th>
                <th className="px-4 py-2 text-left text-sm font-semibold text-gray-700 uppercase">
                  Role
                </th>
                <th className="px-4 py-2 text-center text-sm font-semibold text-gray-700 uppercase">
                  Status
                </th>
                <th className="px-4 py-2 text-center text-sm font-semibold text-gray-700 uppercase">
                  Aksi
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {sortedUsers.length > 0 ? (
                sortedUsers.map((user, index) => {
                
                  return (
                    <tr
                      key={user.userID}
                      className={`hover:bg-gray-50 ${
                        index % 2 === 0 ? "bg-gray-50" : "bg-white"
                      }`}
                    >
                      <td className="px-4 py-2 text-sm">{index + 1}</td>
                      <td className="px-4 py-2 text-sm">{user.userID}</td>
                      <td className="px-4 py-2 text-sm">{user.NRKorNIP}</td>
                      <td className="px-4 py-2 text-sm">{user.Nama}</td>
                      <td className="px-4 py-2 text-sm">{user.Jenis}</td>
                      <td className="px-4 py-2 text-sm">{user.Satuan}</td>
                      <td className="px-4 py-2 text-sm">{user.Role}</td>
                      <td className="px-4 py-2 text-center">
                        {user.isActive ? (
                          <FaCheck className="text-green-500" />
                        ) : (
                          <FaTimes className="text-red-500" />
                        )}
                      </td>
                      <td className="px-4 py-2 text-center">
                        <button
                          onClick={() => {
                            // console.log(
                            //   `View user clicked for UserID: ${user.userID} and NRKorNIP: ${user.NRKorNIP}`
                            // );
                            handleViewUser(user.userID, user.NRKorNIP);
                          }}
                          className="p-2 text-blue-500"
                        >
                          <CgEye />
                        </button>

                        <button
                          onClick={() => handleUpdateStatusUser(user.userID)}
                          className="p-2 text-yellow-500"
                        >
                          <CgPen />
                        </button>
                        <button
                          onClick={() => handleDeleteUser(user.userID)}
                          className="p-2 text-red-500"
                        >
                          <CgTrash />
                        </button>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan="8" className="text-center py-4">
                    Tidak ada data yang ditemukan.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
TableUserlist.propTypes = {
  userID: PropTypes.string.isRequired,
  onViewUser: PropTypes.func, // Tambahkan propType untuk callback
};
export default TableUserlist;
