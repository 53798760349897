import React, { useState, useEffect } from 'react';
import Layout from '../components/Layout';
import axios from 'axios';
import '../ViewAllUsers.css';
import { selectBaseURL } from '../store/url/urlSlice';
import { useSelector } from 'react-redux';

const HomePage = () => {
  const [userStats, setUserStats] = useState({
    petugasOnline: 0,
    activeUsers: 0,
    disableUsers: 0,
    totalUsers: 0,
  });
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const baseURL = useSelector(selectBaseURL);
  const Nrk = localStorage.getItem('NRKorNIP');
  const userID = localStorage.getItem('IDs');
  const token = localStorage.getItem('Token');
  const authToken = `Bearer ${token}`;

  useEffect(() => {
    const fetchActiveUsers = async () => {
      try {
        const response = await axios.get(`${baseURL}/geoapi/user/viewu/${userID}`, {
          headers: {
            Authorization: authToken,
          },
        });

        const data = response.data;
        setUserStats({
          totalUsers: data.length,
          petugasOnline: data.filter((user) => user.isActive).length,
          activeUsers: data.filter((user) => user.StatusAkun === true).length,
          disableUsers: data.filter((user) => user.StatusAkun === false).length,
        });
      } catch (error) {
        console.error('Error fetching user stats:', error);
        setError('Gagal memuat statistik pengguna.');
      }
    };

    fetchActiveUsers();
  }, [baseURL, userID, authToken]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(
          `${baseURL}/geoapi/user/recapData`,
          { NRKorNIP: Nrk },
          {
            headers: {
              Authorization: authToken,
            },
          }
        );

        const usersData = response.data.satuan;
        const usersArray = Object.entries(usersData).map(([unit, data], index) => ({
          id: `temp-${index}`,
          satuan: unit,
          ...data,
        }));

        setUsers(usersArray);
      } catch (error) {
        console.error('Error fetching data:', error);
        setError('Gagal memuat data pengguna.');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [Nrk, baseURL, authToken]);

  if (loading) {
    return <Layout>Loading...</Layout>;
  }

  if (error) {
    return <Layout>{error}</Layout>;
  }

  const { petugasOnline, activeUsers, disableUsers, totalUsers } = userStats;

  return (
    <Layout>
      <div className="p-6 bg-gray-50 dark:bg-gray-800">
        <h2 className="text-3xl font-bold text-center text-gray-800 dark:text-white mb-6">
          Selamat Datang di Geomap
        </h2>

        {/* Cards Row */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 mb-8">
          {[
            {
              title: 'Petugas Online',
              count: petugasOnline,
              color: 'bg-blue-100 text-blue-500',
            },
            {
              title: 'User Aktif',
              count: activeUsers,
              color: 'bg-green-100 text-green-500',
            },
            {
              title: 'User Non-Aktif',
              count: disableUsers,
              color: 'bg-red-100 text-red-500',
            },
            {
              title: 'Total User',
              count: totalUsers,
              color: 'bg-yellow-100 text-yellow-500',
            },
          ].map((card, index) => (
            <div
              key={index}
              className={`${card.color} p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow`}
            >
              <p className="text-lg font-medium text-gray-700">{card.title}</p>
              <h3 className="text-5xl font-bold">
                {card.count}
                <span className="text-base ml-1">User</span>
              </h3>
            </div>
          ))}
        </div>

        {/* Table */}
        <div className="overflow-x-auto bg-white rounded-lg shadow-md">
          <table className="table-auto w-full border-collapse">
            <thead className="bg-gray-200">
              <tr>
                {['No', 'UKPD', 'Total Anggota', 'Jumlah Petugas Aktif', 'Jumlah Petugas Non-Aktif'].map(
                  (header, index) => (
                    <th
                      key={index}
                      className="border-b border-gray-300 py-3 px-4 text-left text-gray-600 font-semibold"
                    >
                      {header}
                    </th>
                  )
                )}
              </tr>
            </thead>
            <tbody>
              {users.map((user, index) => (
                <tr
                  key={user.id}
                  className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}
                >
                  <td className="border-b border-gray-300 py-3 px-4">{index + 1}</td>
                  <td className="border-b border-gray-300 py-3 px-4">{user.satuan}</td>
                  <td className="border-b border-gray-300 py-3 px-4">{user.Total_Anggota}</td>
                  <td className="border-b border-gray-300 py-3 px-4">{user.Petugas_Aktif}</td>
                  <td className="border-b border-gray-300 py-3 px-4">{user.Petugas_Suspen}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </Layout>
  );
};

export default HomePage;