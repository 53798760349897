import React, { useState } from 'react';
import Swal from 'sweetalert2';
import Layout from '../components/Layout';
import { Link } from 'react-router-dom';

const NewUserForm = () => {
  //state untuk menyimpan data pengguna baru
  const [formData, setFormData] = useState({
    NRKorNIP: '',
    Nama: '',
    Email: '',
    Wilayah: '',
    Satuan: '',
    Role: '',
    UserID: '',
    UniqueCode: '',
  });
  //Fungsi untuk mengubah data formulir saat nilai input berubah
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
//Fungsi untuk mengirim formulir dan membuat penggunna baru
  const handleSubmit = (e) => {
    e.preventDefault();
    Swal.fire({
      title: 'Yakin ingin membuat user baru?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Ya',
      cancelButtonText: 'Tidak',
    }).then((result) => {
      if (result.isConfirmed) {
        // Lakukan aksi pembuatan user di sini
        // console.log('Data User Baru:', formData);

        // Tampilkan notifikasi sukses
        Swal.fire({
          title: 'User baru berhasil dibuat!',
          icon: 'success',
        });

        // Reset form
        setFormData({
          NRKorNIP: '',
          Nama: '',
          Email: '',
          Wilayah: '',
          Satuan: '',
          Role: '',
          UserID: '',
          UniqueCode: '',
        });
      }
    });
  };

  return (
    <Layout>
      <div className="flex-grow flex items-center justify-center">
        <div className="max-w-md border p-4">
          <h2 className="text-xl dark:text-white text-center font-semibold mb-4">
            Create User
          </h2>
          <form onSubmit={handleSubmit} className="grid gap-4 grid-cols-2">
            {/* First Column */}
            <div>
              <label className="block dark:text-white text-sm font-medium mb-1">
                NRK or NIP
              </label>
              <input
                type="text"
                name="NRKorNIP"
                value={formData.NRKorNIP}
                onChange={handleChange}
                className="w-full p-2 border rounded-md focus:outline-none focus:ring focus:ring-blue-300"
                required
              />
            </div>
            <div>
              <label className="block dark:text-white text-sm font-medium mb-1">
                Nama
              </label>
              <input
                type="text"
                name="Nama"
                value={formData.Nama}
                onChange={handleChange}
                className="w-full p-2 border rounded-md focus:outline-none focus:ring focus:ring-blue-300"
                required
              />
            </div>
            <div>
              <label className="block dark:text-white text-sm font-medium mb-1">
                Email
              </label>
              <input
                type="text"
                name="Email"
                value={formData.Email}
                onChange={handleChange}
                className="w-full p-2 border rounded-md focus:outline-none focus:ring focus:ring-blue-300"
                required
              />
            </div>
            <div>
              <label className="block dark:text-white text-sm font-medium mb-1">
                Wilayah
              </label>
              <input
                type="text"
                name="Wilayah"
                value={formData.Wilayah}
                onChange={handleChange}
                className="w-full p-2 border rounded-md focus:outline-none focus:ring focus:ring-blue-300"
                required
              />
            </div>

            {/* Second Column */}
            <div>
              <label className="block dark:text-white text-sm font-medium mb-1">
                Satuan
              </label>
              <input
                type="text"
                name="Satuan"
                value={formData.Satuan}
                onChange={handleChange}
                className="w-full p-2 border rounded-md focus:outline-none focus:ring focus:ring-blue-300"
                required
              />
            </div>
            <div>
              <label className="block dark:text-white text-sm font-medium mb-1">
                Role
              </label>
              <input
                type="text"
                name="Role"
                value={formData.Role}
                onChange={handleChange}
                className="w-full p-2 border rounded-md focus:outline-none focus:ring focus:ring-blue-300"
                required
              />
            </div>
            <div className="col-span-2">
              <label className="block dark:text-white text-sm font-medium mb-1">
                User ID
              </label>
              <textarea
                name="UserID"
                value={formData.UserID}
                onChange={handleChange}
                className="w-full p-2 border rounded-md focus:outline-none focus:ring focus:ring-blue-300"
                rows="4"
                required
              />
            </div>
            <div className="col-span-2">
              <label className="block dark:text-white text-sm font-medium mb-1">
                Unique Code
              </label>
              <textarea
                name="UniqueCode"
                value={formData.UniqueCode}
                onChange={handleChange}
                className="w-full p-2 border rounded-md focus:outline-none focus:ring focus:ring-blue-300"
                rows="4"
                required
              />
            </div>
            <div className="col-span-2 flex justify-center">
              <Link to="/ViewAllUsers" className="mr-4">
                <button className="bg-gray-300 text-gray-700 py-2 px-4 rounded-md hover:bg-gray-400">
                  Back
                </button>
              </Link>
              <button
                type="submit"
                className="bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600"
              >
                Create User
              </button>
            </div>
          </form>
        </div>
      </div>
    </Layout>
  );
};

export default NewUserForm;
