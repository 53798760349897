import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import moment from "moment";
import PropTypes from "prop-types";
import { selectBaseURL } from "../store/url/urlSlice";
import { useParams } from "react-router-dom";

const TableUserlog = ({ userID, nrkOrNip }) => {
  
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const { nrkOrNip: paramNrkOrNip } = useParams();
  const nrk_or_nip = nrkOrNip || paramNrkOrNip || localStorage.getItem("NRKorNIP");
  const baseURL = useSelector(selectBaseURL);
  const token = localStorage.getItem('Token');
  const authToken = `Bearer ${token}`;

 
  TableUserlog.propTypes = {
    userID: PropTypes.string.isRequired,
  };

  useEffect(() => {
    const fetchData = async () => {
      if (!nrk_or_nip) {
        console.error("NRKorNIP tidak ditemukan di localStorage.");
        setLoading(false);
        return;
      }

      try {
        setLoading(true); // Set loading to true when starting fetch
        const response = await axios.get(
          `${baseURL}/geoapi/gpsweb/viewUserLog?nrk_or_nip=${nrk_or_nip}`,
          {
            headers: {
              Authorization: authToken, // Add Authorization header
            },
          }
        );
        const usersData = response.data.data;
       
        // Sort the data by time_stamp_login in descending order (latest first)
        const sortedUsers = usersData.sort((a, b) => {
          const dateA = moment(a.time_stamp_login);
          const dateB = moment(b.time_stamp_login);
          return dateB - dateA; // Sort by descending order
        });

        setUsers(sortedUsers); // Save sorted data to state
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false); // Set loading to false after finish
      }
    };

    fetchData();
  }, [userID, baseURL, nrk_or_nip, authToken]);

  const formatTimestamp = (timestamp) => {
    if (!timestamp) {
      return "Tidak Ada";
    }

    // Format only hours and minutes
    return moment(timestamp).format("HH:mm");
  };

  const formatDate = (timestamp) => {
    if (!timestamp) {
      return "Tanggal tidak tersedia";
    }
    return moment(timestamp).format("DD-MM-YYYY");
  };

  // Format date for the "Tanggal" row
  const formatTanggal = (loginDate, logoutDate) => {
    const login = moment(loginDate);
    const logout = logoutDate ? moment(logoutDate) : null;

    if (!logout) {
      // If logoutDate is null, display "s/d Tidak Checkout"
      return `${login.format("DD-MM-YYYY")} s/d Tidak Checkout`;
    }

    if (login.isSame(logout, "day")) {
      // If login and logout dates are the same, display only the login date
      return login.format("DD-MM-YYYY");
    } else {
      // If the dates are different, display the date range
      return `${login.format("DD-MM-YYYY")} s/d ${logout.format("DD-MM-YYYY")}`;
    }
  };

  if (loading) {
    return (
      <div className="p-4 text-center">
        <p>Loading data...</p>
      </div>
    );
  }

  return (
    <div className="p-4 bg-gray-50">
      <h2 className="text-2xl font-bold text-center mb-4">History</h2>
      <div className="overflow-x-auto shadow-md rounded-lg">
        <div className="max-h-[500px] overflow-y-auto">
          <table className="min-w-full divide-y divide-gray-200 bg-white">
            <thead className="sticky top-0 bg-gray-100 z-10">
              <tr>
                <th className="px-4 py-2 text-left text-sm font-semibold text-gray-700 uppercase">No</th>
                <th className="px-4 py-2 text-left text-sm font-semibold text-gray-700 uppercase">Tanggal</th>
                <th className="px-4 py-2 text-left text-sm font-semibold text-gray-700 uppercase">Lokasi CheckIn</th>
                <th className="px-4 py-2 text-left text-sm font-semibold text-gray-700 uppercase">Lokasi Checkout</th>
                <th className="px-4 py-2 text-left text-sm font-semibold text-gray-700 uppercase">Timestamp Checkin</th>
                <th className="px-4 py-2 text-left text-sm font-semibold text-gray-700 uppercase">Timestamp Checkout</th>
                <th className="px-4 py-2 text-center text-sm font-semibold text-gray-700 uppercase">Durasi</th>
              </tr>
            </thead>

            <tbody className="divide-y divide-gray-200">
              {users.length > 0 ? (
                users.map((user, index) => (
                  <tr
                    key={user.userID}
                    className={`hover:bg-gray-50 ${index % 2 === 0 ? "bg-gray-50" : "bg-white"}`}
                  >
                    <td className="px-4 py-2 text-sm">{index + 1}</td>
                    <td className="px-4 py-2 text-sm">
                      {formatTanggal(user.time_stamp_login, user.time_stamp_logout)}
                    </td>
                    <td className="px-4 py-2 text-sm w-1/5">
                      {user.login_latitude && user.login_longitude
                        ? `${user.login_latitude}, ${user.login_longitude}`
                        : "Tidak Ada"}
                    </td>
                    <td className="px-4 py-2 text-sm w-1/5">
                      {user.logout_latitude && user.logout_longitude
                        ? `${user.logout_latitude}, ${user.logout_longitude}`
                        : "Tidak Ada"}
                    </td>
                    <td className="px-4 py-2 text-sm">
                      {formatTimestamp(user.time_stamp_login)}
                    </td>
                    <td className="px-4 py-2 text-sm">
                      {formatTimestamp(user.time_stamp_logout)}
                    </td>
                    <td className="px-4 py-2 text-sm w-1/6">{user.duration}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="7" className="px-4 py-2 text-center text-sm">
                    Tidak ada data ditemukan.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default TableUserlog;
