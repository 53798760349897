import React from 'react';
import Swal from 'sweetalert2';
import axios from 'axios'; // Pastikan ini diimpor jika axios digunakan
import { selectBaseURL } from '../store/url/urlSlice';
import { useSelector } from 'react-redux';
const Logout = () => {
  const token = localStorage.getItem('Token');
  const authToken = `Bearer ${token}`;
  const baseURL = useSelector(selectBaseURL); // Ganti dengan URL backend Anda

  const handleLogout = async () => {
    // Tampilkan SweetAlert untuk konfirmasi logout
    Swal.fire({
      title: 'Konfirmasi Logout',
      text: 'Anda yakin akan logout?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Logout',
      cancelButtonText: 'Cancel',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          // Lakukan permintaan logout ke backend
          await axios.get(`${baseURL}/geoapi/user/logOut`, {
            headers: {
              Authorization: authToken,
            },
          });

          // Tampilkan SweetAlert "Logout berhasil"
          Swal.fire({
            title: 'Logout Berhasil',
            text: 'Anda telah berhasil logout.',
            icon: 'success',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'OK',
          }).then(() => {
            // Hapus token dari localStorage
            localStorage.removeItem('Token');
            // Mengalihkan ke halaman login setelah logout berhasil
            window.location.href = '/'; // Ganti dengan path menuju halaman login yang sesuai
          });
        } catch (error) {
          // Tampilkan error jika terjadi masalah saat logout
          console.error('Error during logout:', error);
          Swal.fire({
            title: 'Logout Gagal',
            text: 'Terjadi kesalahan saat logout. Silakan coba lagi.',
            icon: 'error',
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK',
          });
        }
      }
    });
  };

  return (
    <div className="p-4">
      <button
        onClick={handleLogout}
        className="bg-red-500 text-white px-4 py-2 rounded font-medium"
      >
        Logout
      </button>
    </div>
  );
};

export default Logout;
