import React, { useEffect, useState } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import 'tailwindcss/tailwind.css';
import L from 'leaflet';
import axios from 'axios';
import pusatIcon from '../assets/images/Icon-SudinPusat.png';
import selatanIcon from '../assets/images/Icon-SudinSelatan.png';
import utaraIcon from '../assets/images/Icon-SudinUtara.png';
import baratIcon from '../assets/images/Icon-SudinBarat.png';
import timurIcon from '../assets/images/Icon-SudinTimur.png';
import userIcon from '../assets/images/icon-petugas.png';
import Layout from '../components/Layout';
import { FullscreenControl } from "react-leaflet-fullscreen";

const MapWithTetra = () => {
  // State untuk menyimpan posisi pengguna
  const [userPosition, setUserPosition] = useState(null);
  const token = localStorage.getItem('Token');
  const authToken = `Bearer ${token}`;
  // State untuk menyimpan lokasi pengguna lain
  const [userLocations, setUserLocations] = useState([]);
  const [derekIdleLocation, setDerekIdleLocation] = useState([]);
  const [derekOffLocation, setDerekOffLocation] = useState([]);
  const [derekOnLocation, setDerekOnLocation] = useState([]);
  const [selectedJenisFilter, setSelectedJenisFilter] = useState('all');
  const [selectedSatuanFilter, setSelectedSatuanFilter] = useState('all');
  const [selectedDerekStatusFilter, setSelectedDerekStatusFilter] = useState('all');
  const [satuanOptions, setSatuanOptions] = useState([]);

  const Satuan = localStorage.getItem('Satuan');
  // console.log(Satuan);
  const userID = localStorage.getItem('IDs');
  // Fungsi untuk mengambil lokasi pengguna saat ini
  const fetchUserLocation = async () => {
    try {
      const position = await new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject);
      });
      const { latitude, longitude } = position.coords;
      setUserPosition([latitude, longitude]);
    } catch (error) {
      console.error('Error getting geolocation:', error);
      alert('Error getting geolocation. Mohon izinkan akses lokasi dan coba lagi.');
    }
  };

  // Fungsi untuk mengambil lokasi pengguna lain
  const fetchUserLocations = async () => {
    try {
      const response = await axios.get(`http://192.168.60.54:5050/geoapi/gpsweb/tetra`);
      setUserLocations(response.data.dt);

      // console.log(response.data.dt);

      const satuanSet = new Set();
      response.data.data.dt.forEach((item) => satuanSet.add(item.satuan));
      setSatuanOptions([...satuanSet]);

    } catch (error) {
      console.error('Error fetching data from the API:', error);
    }
  };

  useEffect(() => {
    // fetchUserLocation();
    fetchUserLocations();
    const intervalId = setInterval(fetchUserLocations, 15 * 1000);
    return () => clearInterval(intervalId);
  }, []);

  //Fungsi untuk memformat waktu dan tanggal
  function formatDateTime(timestamp) {
    if (!timestamp) return '-';

    const date = new Date(timestamp);
    const dateString = date.toLocaleDateString();
    const timeString = date.toLocaleTimeString();

    return `${dateString} - ${timeString}`;
  }
  const getIcon = (Satuan) => {
    const icons = {
      'Sudinhub Jakarta Pusat': pusatIcon,
      'Sudinhub Jakarta Selatan': selatanIcon,
      'Sudinhub Jakarta Utara': utaraIcon,
      'Sudinhub Jakarta Timur': timurIcon,
      'Sudinhub Jakarta Barat': baratIcon
    };
    return L.icon({ iconUrl: icons[Satuan] || userIcon, iconSize: [32, 32] });
  };

  
  //Render peta dan informasi terkait
  return (
    <Layout>
      <div>
        <h1 className="text-2xl text-center dark:text-white font-bold mb-4">Lokasi Petugas HT Tetra</h1>
        <div className="flex justify-center mb-4">

        </div>
        <div className=" h-screen items-center justify-center">
          <MapContainer center={userPosition || [-6.1754, 106.8272]} zoom={13} style={{ width: '80%', height: '75%', marginLeft: '120px' }}>
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
            <FullscreenControl />
            {userLocations.map((location, index) => (
              <Marker key={index} position={[location.latitude, location.longitude]} icon={getIcon(userIcon)}>
                <Popup>
                  <div>
                    <h2 className="text-lg font-semibold mb-2">User Location Detail</h2>
                    <p><strong>ID:</strong> {location.ID_HT}</p>
                    <p><strong>Nama:</strong> {location.Nama_HT}</p>
                    <p><strong>Last Update:</strong> {formatDateTime(location.Last_update)}</p>
                  </div>
                </Popup>
              </Marker>
            ))}
          </MapContainer>
        </div>
      </div>
    </Layout>
  );
};

export default MapWithTetra;
